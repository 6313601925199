import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import ActivityTimeBarGraph from 'components/personalInfo/ActivityTimeBarGraph';
import AwarnessDistributionBarGraph from 'components/personalInfo/AwarnessDistributionBarGraph';
import EvaluationSkillsBarGraph from 'components/personalInfo/EvaluationSkillsBarGraph';
import PostNumberBarGraph from 'components/personalInfo/PostNumberBarGraph';
import ThinkingBarGraph from 'components/personalInfo/ThinkingBarGraph';
import TimeSpendOnCheckInBarGraph from 'components/personalInfo/TimeSpendOnCheckInBarGraph';
import TimeSpendOnCloserLookBarGraph from 'components/personalInfo/TimeSpendOnCloserLookBarGraph';
import EngagementCategories from 'components/personalInfo/EngagementCategories';
import CustomSelect from 'components/peoplePage/CustomSelect';
import SettingIcon from 'assets/icons/settingicon';
import axios from 'axios';
import { useGate } from 'statsig-react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import CustomiseIcon from 'assets/icons/CustomiseIocn';
import analytics from 'segmentTracking';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../components/Select';
import { getDisplayValue } from '../../../utils/getDisplayName';
import { handleSessionTimeout } from 'utils/sessionManagementUtils';
import api from '../../../utils/api';

const userInfo = {
  label: 'All Users',
};

const ThinkingBarGraphData = [
  { xAxis: 10, value1: 'Low', value2: 'High' },
  { xAxis: 20, value1: 'High', value2: 'Low' },
  { xAxis: 30, value1: 'Low', value2: 'Medium' },
  { xAxis: 40, value1: 'Medium', value2: 'High' },
  { xAxis: 50, value1: 'Medium', value2: 'Low' },
  { xAxis: 60, value1: 'High', value2: 'Medium' },
  { xAxis: 70, value1: 'Low', value2: 'Medium' },
  { xAxis: 80, value1: 'Low', value2: 'High' },
  { xAxis: 90, value1: 'Medium', value2: 'Low' },
  { xAxis: 100, value1: 'Low', value2: 'Medium' },
];

const topicsData = [
  { name: '2024 Elections', value: 40 },
  { name: 'Fitness', value: 21 },
  { name: 'Olympics 2024', value: 14 },
  { name: 'AI', value: 10 },
  { name: 'Food', value: 8 },
  { name: 'Climate', value: 7 },
];

const topicsBubbleData = [
  {
    name: 'Media',
    children: [
      { name: '2024 Elections', value: 40 },
      { name: 'Trump', value: 30 },
      { name: 'Ukraine', value: 20 },
      { name: 'UK Riots', value: 25 },
      { name: 'NYT', value: 28 },
    ],
  },
  {
    name: 'Tech',
    children: [
      { name: 'AI', value: 35 },
      { name: 'Climate Tech', value: 30 },
      { name: 'Nvidia', value: 25 },
      { name: 'VC', value: 20 },
      { name: 'X', value: 15 },
    ],
  },
  {
    name: 'Health',
    children: [
      { name: 'Olympics', value: 35 },
      { name: 'FDA', value: 25 },
      { name: 'Food', value: 20 },
    ],
  },
];

const Performance = () => {
  const [currentUser] = useState(JSON.parse(localStorage.getItem('user')));
  const { value: performanceThinkingBargraph } = useGate(
    'performance_thinking_bargraph',
  );

  const customiseRef = useRef(null);
  const buttonRef = useRef(null);

  const [isLargerThan850] = useMediaQuery('(min-width: 850px)');
  // const [period, setPeriod] = useState('6months');
  // const [loading, setLoading] = useState(true); // Loading state
  // const [error, setError] = useState(null); // Error state
  const [isCustomiseOpen, setIsCustomiseOpen] = useState(false);

  const [customiseData, setCustomiseData] = useState([
    { id: 'Engagement', content: 'Engagement' },
    { id: 'Awareness', content: 'Awareness' },
    { id: 'Analysis', content: 'Analysis' },
    { id: 'Thinking', content: 'Thinking' },
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isCustomiseOpen &&
        customiseRef.current &&
        !customiseRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsCustomiseOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isCustomiseOpen]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from(customiseData);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setCustomiseData(newItems);
  };

  const [periods, setPeriods] = useState({
    performancePage: 'month',
    postNumber: 'month',
    activityTime: 'month',
    timeSpendOnCheckIn: 'month',
    awarenessDistribution: 'month',
    timeSpendOnCloserLook: 'month',
    evaluationSkills: 'month',
    thinking: 'month',
    categories: 'month',
  });
  const [graphData, setGraphData] = useState({
    performancePage: null,
    postNumber: null,
    activityTime: null,
    timeSpendOnCheckIn: null,
    awarenessDistribution: null,
    timeSpendOnCloserLook: null,
    evaluationSkills: null,
    thinking: null,
    categories: null,
  });

  const periodOptions = [
    { value: 'all', display: 'All' },
    { value: 'week', display: 'Last Week' },
    { value: 'month', display: 'Last Month' },
    { value: '3months', display: 'Last 3 Months' },
    { value: '6months', display: 'Last 6 Months' },
    { value: 'year', display: 'Last Year' },
  ];

  //api integration

  const [selectedOption, setSelectedOption] = useState({
    timeSpendOnCloserLook: { value: 'avg', label: 'Average' },
    evaluationSkills: { value: 'avg', label: 'Average' },
    awarenessDistribution: { value: 'avg', label: 'Average' },
    timeSpendOnCheckIn: { value: 'avg', label: 'Average' },
    activityTime: { value: 'total', label: 'Total' },
  });

  const fetchData = useCallback(
    async (endpoint, period, dataKey, chartData) => {
      try {
        const token = localStorage.getItem('token');
        const currentInstitution = JSON.parse(
          localStorage.getItem('institution'),
        );

        const params = new URLSearchParams({
          institution: currentInstitution?._id || '',
        });

        if (period !== 'all') {
          params.append('period', period);
        }

        const url = chartData
          ? `/${endpoint}?${params.toString()}&chartData=${chartData}`
          : `/${endpoint}?${params.toString()}`;

        const response = await api.get(url);

        setGraphData((prevData) => ({
          ...prevData,
          [dataKey]: response.data.data,
        }));
      } catch (error) {
        // setError(error);
        console.error(`Error fetching ${dataKey} data:`, error);
        handleSessionTimeout(error);
      }
    },
    [],
  );

  useEffect(() => {
    const fetchAllData = async () => {
      await Promise.all([
        fetchData(
          'dashboard/users/getPostsNumbers',
          periods?.postNumber,
          'postNumber',
        ),
        fetchData(
          'dashboard/users/getActivityTime',
          periods?.activityTime,
          'appActivity',
          selectedOption?.activityTime?.value,
        ),
        fetchData(
          'dashboard/users/getTimeSpentOnCheckIn',
          periods?.timeSpendOnCheckIn,
          'timeSpendOnCheckIn',
          selectedOption?.timeSpendOnCheckIn?.value,
        ),
        fetchData(
          'dashboard/users/getAwarenessDistribution',
          periods?.awarenessDistribution,
          'awarenessDistribution',
          selectedOption?.awarenessDistribution?.value,
        ),
        fetchData(
          'dashboard/users/getTimeSpentOnCloserLook',
          periods?.timeSpendOnCloserLook,
          'timeSpendOnCloserLook',
          selectedOption?.timeSpendOnCloserLook?.value,
        ),
        fetchData(
          'dashboard/users/getEvaluationSkills',
          periods?.evaluationSkills,
          'evaluationSkills',
          selectedOption?.evaluationSkills?.value,
        ),

        fetchData(
          'dashboard/users/getCategoriesData',
          periods.categories,
          'categories',
        ),
        // fetchData('dashboard/users/thinking', periods.thinking, 'thinking'),
      ]);
    };

    fetchAllData();
  }, [fetchData, periods, selectedOption]);

  const updateAllPeriods = (newPeriod) => {
    setPeriods((prevPeriods) => {
      const updatedPeriods = {};
      for (const key in prevPeriods) {
        updatedPeriods[key] = newPeriod;
      }
      return updatedPeriods;
    });
  };

  const handlePeriodChange = (dataKey, newPeriod) => {
    if (dataKey === 'performancePage') {
      analytics.track(`performancepage:customiselayout:selected:${newPeriod}`, {
        email: currentUser.email,
        userRole: currentUser?.role,
        graphName: dataKey,
        period: newPeriod,
      });

      updateAllPeriods(newPeriod);
    } else {
      analytics.track(
        `performancepage:${
          dataKey === 'evaluationSkills' ? 'analyticalskills' : dataKey
        }:selected:${newPeriod}`,
        {
          email: currentUser.email,
          userRole: currentUser?.role,
          graphName: dataKey,
          period: newPeriod,
        },
      );

      setPeriods((prevPeriods) => ({
        ...prevPeriods,
        [dataKey]: newPeriod,
      }));
    }
  };

  const renderComponent = (id) => {
    switch (id) {
      case 'Engagement':
        return (
          <Box id="Engagement" width={'100%'} marginTop={'40px'}>
            <Text fontSize={'20px'} fontWeight={'bold'} ml={'6px'} mb={'12px'}>
              Engagement
            </Text>
            <Flex
              width={'100%'}
              gap={isLargerThan850 ? '2%' : '5%'}
              flexDirection={isLargerThan850 ? 'row' : 'column'}
            >
              <Box
                width={isLargerThan850 ? '48%' : '100%'}
                mb={isLargerThan850 ? '' : '18px'}
              >
                <PostNumberBarGraph
                  data={graphData?.postNumber?.data}
                  graphData={graphData?.postNumber}
                  period={periods?.postNumber}
                  onPeriodChange={(newPeriod) =>
                    handlePeriodChange('postNumber', newPeriod)
                  }
                  periodOptions={periodOptions}
                  barLables={graphData?.postNumber?.labels}
                  chipsPercentage={graphData?.postNumber?.percentage}
                />
              </Box>
              <Box width={isLargerThan850 ? '48%' : '100%'}>
                <ActivityTimeBarGraph
                  data={graphData?.appActivity?.times}
                  period={periods.activityTime}
                  onPeriodChange={(newPeriod) =>
                    handlePeriodChange('activityTime', newPeriod)
                  }
                  periodOptions={periodOptions}
                  barLabels={graphData?.appActivity?.labels}
                  graphData={graphData?.appActivity}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  pageName={'performance'}
                  currentUser={currentUser}
                />
              </Box>
            </Flex>
            <Flex width={'100%'} marginTop={'20px'}>
              <Box width={isLargerThan850 ? '65%' : '100%'}>
                <EngagementCategories
                  categorieData={graphData?.categories?.categories}
                  topicsData={topicsData}
                  pageName={'performance'}
                  topicsBubbleData={topicsBubbleData}
                  periodOptions={periodOptions}
                  period={periods.categories}
                  onPeriodChange={(newPeriod) =>
                    handlePeriodChange('categories', newPeriod)
                  }
                />
              </Box>
            </Flex>
          </Box>
        );
      case 'Awareness':
        return (
          <Box id="Awareness" width={'100%'} marginTop={'40px'}>
            <Text fontSize={'20px'} fontWeight={'bold'} ml={'6px'} mb={'12px'}>
              Awareness
            </Text>
            <Flex
              width={'100%'}
              gap={isLargerThan850 ? '2%' : '5%'}
              flexDirection={isLargerThan850 ? 'row' : 'column'}
            >
              <Box
                width={isLargerThan850 ? '48%' : '100%'}
                mb={isLargerThan850 ? '' : '18px'}
              >
                <TimeSpendOnCheckInBarGraph
                  data={graphData?.timeSpendOnCheckIn?.checkInTime}
                  period={periods.timeSpendOnCheckIn}
                  onPeriodChange={(newPeriod) =>
                    handlePeriodChange('timeSpendOnCheckIn', newPeriod)
                  }
                  periodOptions={periodOptions}
                  barLabels={graphData?.timeSpendOnCheckIn?.labels}
                  graphData={graphData?.timeSpendOnCheckIn}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  userInfo={userInfo}
                  pageName={'performance'}
                  currentUser={currentUser}
                />
              </Box>
              <Box width={isLargerThan850 ? '48%' : '100%'}>
                <AwarnessDistributionBarGraph
                  data={graphData?.awarenessDistribution?.questions}
                  period={periods.awarenessDistribution}
                  onPeriodChange={(newPeriod) =>
                    handlePeriodChange('awarenessDistribution', newPeriod)
                  }
                  periodOptions={periodOptions}
                  barLabels={graphData?.awarenessDistribution?.labels}
                  graphData={graphData?.awarenessDistribution}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  pageName={'performance'}
                  currentUser={currentUser}
                />
              </Box>
            </Flex>
          </Box>
        );
      case 'Analysis':
        return (
          <Box id="Analysis" width={'100%'} marginTop={'40px'}>
            <Text fontSize={'20px'} fontWeight={'bold'} ml={'6px'} mb={'12px'}>
              Analysis
            </Text>
            <Flex
              width={'100%'}
              gap={isLargerThan850 ? '2%' : '5%'}
              flexDirection={isLargerThan850 ? 'row' : 'column'}
            >
              <Box
                width={isLargerThan850 ? '48%' : '100%'}
                mb={isLargerThan850 ? '' : '18px'}
              >
                <TimeSpendOnCloserLookBarGraph
                  data={graphData?.timeSpendOnCloserLook?.closerLookTime}
                  period={periods.timeSpendOnCloserLook}
                  onPeriodChange={(newPeriod) =>
                    handlePeriodChange('timeSpendOnCloserLook', newPeriod)
                  }
                  periodOptions={periodOptions}
                  barLabels={graphData?.timeSpendOnCloserLook?.labels}
                  graphData={graphData?.timeSpendOnCloserLook}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  userInfo={userInfo}
                  pageName={'performance'}
                  currentUser={currentUser}
                />
              </Box>
              <Box width={isLargerThan850 ? '48%' : '100%'}>
                <EvaluationSkillsBarGraph
                  data={graphData?.evaluationSkills?.questions}
                  period={periods.evaluationSkills}
                  onPeriodChange={(newPeriod) =>
                    handlePeriodChange('evaluationSkills', newPeriod)
                  }
                  periodOptions={periodOptions}
                  barLabels={graphData?.evaluationSkills?.labels}
                  graphData={graphData?.evaluationSkills}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  pageName={'performance'}
                  currentUser={currentUser}
                />
              </Box>
            </Flex>
          </Box>
        );
      case 'Thinking':
        return performanceThinkingBargraph ? (
          <Box id="Thinking" width={'100%'} marginTop={'40px'}>
            <Text fontSize={'20px'} fontWeight={'bold'} ml={'6px'} mb={'12px'}>
              Thinking
            </Text>
            <Flex
              width={'100%'}
              gap={isLargerThan850 ? '2%' : '5%'}
              flexDirection={isLargerThan850 ? 'row' : 'column'}
            >
              <Box
                width={isLargerThan850 ? '48%' : '100%'}
                mb={isLargerThan850 ? '' : '18px'}
              >
                <ThinkingBarGraph
                  data={ThinkingBarGraphData}
                  period={periods.thinking}
                  onPeriodChange={(newPeriod) =>
                    handlePeriodChange('thinking', newPeriod)
                  }
                  periodOptions={periodOptions}
                />
              </Box>
              <Box width="48%">{/* <ActivityTimeBarGraph /> */}</Box>
            </Flex>
          </Box>
        ) : null;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        pos={'relative'}
        pt={10}
      >
        <Text
          fontSize={'28px'}
          fontWeight={'bold'}
          color={'#222222'}
          lineHeight={'32px'}
        >
          Performance
        </Text>
        <Flex gap={'8px'}>
          <Select
            onValueChange={(newPeriod) =>
              handlePeriodChange('performancePage', newPeriod)
            }
          >
            <SelectTrigger className="w-[150px]">
              <SelectValue
                placeholder={getDisplayValue(
                  periods.performancePage,
                  periodOptions,
                )}
              />
            </SelectTrigger>
            <SelectContent>
              {periodOptions?.map((elem, index) => (
                <SelectItem value={elem?.value} key={index}>
                  {elem?.display}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Flex
            ref={buttonRef}
            height={'36px'}
            width={'36px'}
            border={'1px solid #E7E7E7'}
            _hover={{ border: '1px solid #EF6506' }}
            borderRadius={'8px'}
            justifyContent={'center'}
            alignItems={'center'}
            cursor={'pointer'}
            onClick={() => setIsCustomiseOpen(!isCustomiseOpen)}
          >
            <SettingIcon />
          </Flex>
        </Flex>

        {isCustomiseOpen && (
          <Box
            ref={customiseRef}
            width={'15%'}
            bg={'#FFFFFF'}
            pos={'absolute'}
            top={'110%'}
            right={'0%'}
            borderRadius={'8px'}
            border={'1px solid #EF6506'}
          >
            <Box padding={'10px 0 10px 16px'}>
              <Text
                fontWeight={'500'}
                fontSize={'14px'}
                lineHeight={'20px'}
                color={'#222222'}
              >
                Customise layout
              </Text>
            </Box>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {customiseData.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided) => (
                          <Flex
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            justify="flex-start"
                            align="center"
                            padding="10px 0 10px 16px"
                            cursor="move"
                          >
                            <CustomiseIcon />
                            <Text
                              marginLeft="8px"
                              fontWeight="500"
                              fontSize="14px"
                              lineHeight="20px"
                              color="#222222"
                            >
                              {item.content}
                            </Text>
                          </Flex>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        )}
      </Box>
      {/* Heading */}
      <Flex
        flexDirection={'column'}
        justifyContent={'space-between'}
        alignItems={'center'}
        pt={6}
        gap={4}
      >
        <Text
          fontSize={'32px'}
          fontWeight={'500'}
          lineHeight={'48px'}
          color={'#222222'}
        >
          👋 Hi there! Welcome to a Twella performance
        </Text>
        <Text
          fontSize={'18px'}
          fontWeight={'400'}
          lineHeight={'27px'}
          color={'#222222'}
          alignSelf={'center'}
          textAlign={'center'}
        >
          Gain deeper insights into your users and their app usage patterns.
          Stay on top of everything that matters to you.
        </Text>
      </Flex>
      {/* Graphs */}
      {customiseData.map((item) => renderComponent(item.id))}
    </Box>
  );
};

export default Performance;
