// Chakra Imports
import {
  Flex,
  Icon,
  // Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  // useColorMode,
  useToast,
  Avatar,
} from '@chakra-ui/react';
// Custom Components
import { ItemContent } from 'components/menu/ItemContent';
// import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
// import { MdNotificationsNone, MdInfoOutline } from 'react-icons/md';
// import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { FaEthereum } from 'react-icons/fa';
// import BellIcon from 'assets/icons/bellIcon';
import { Link, useNavigate } from 'react-router-dom';
import { useRoutes } from 'routes';
import { useClerk } from '@clerk/clerk-react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import analytics from 'segmentTracking';
import api from '../../utils/api';

export default function HeaderLinks(props) {
  const { secondary } = props;
  const [currentUser] = useState(JSON.parse(localStorage.getItem('user')));
  const { signOut } = useClerk();
  const [userFullName, setUserFullName] = useState('');
  const [userName, setUserName] = useState('');
  const [userProfile, setUserProfile] = useState('');
  // const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  // const navbarIcon = useColorModeValue('gray.400', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  const ethColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const ethBox = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );
  // const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');
  const navigate = useNavigate();
  const toast = useToast();
  const routes = useRoutes();
  const userFullNName = useSelector((state) => state.user.fullName);
  const userProfileImage = useSelector((state) => state.user.profileImage);

  const handleLogout = async (e) => {
    analytics.track(`clicked:logout`, {
      properties: {
        userRole: currentUser?.role,
        email: currentUser?.email,
      },
    });

    // Prevent the default form submission behavior if this is triggered by a form
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    try {
      localStorage.clear();
      sessionStorage.clear();

      await signOut({ redirect: false });

      navigate('/auth/sign-in', { replace: true });

      toast({
        title: 'Logged out successfully',
        status: 'success',
        duration: 10000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error during logout:', error);

      // Show an error toast
      toast({
        title: 'Logout failed',
        description: 'An error occurred while logging out. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const currentUserUpdate = useCallback(async (body = {}) => {
    try {
      const response = await api.patch(`/dashboard/user/profile/`, body);

      if (response?.data?.success) {
        const fullName = response?.data?.data?.fullName;
        const name = response?.data?.data?.name;
        setUserFullName(fullName);
        setUserName(name);
        setUserProfile(response?.data?.data?.profilePic);
      } else {
        console.log('NETWORK ISSUE');
      }
    } catch (error) {
      console.log(`Error -> ${error?.message}`);
    }
  }, []);

  useEffect(() => {
    currentUserUpdate();
  }, []); // eslint-disable-line

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      // bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      // p="6px"
      gap={'10px'}
      // borderRadius="30px"
      // boxShadow={shadow}
    >
      {/* <SearchBar
        mb={() => {
          if (secondary) {
            return { base: '10px', md: 'unset' };
          }
          return 'unset';
        }}
        me="10px"
        borderRadius="30px"
      /> */}
      <Flex
        bg={ethBg}
        display={secondary ? 'flex' : 'none'}
        borderRadius="30px"
        ms="auto"
        p="6px"
        align="center"
        me="6px"
      >
        <Flex
          align="center"
          justify="center"
          bg={ethBox}
          h="29px"
          w="29px"
          borderRadius="30px"
          me="7px"
        >
          <Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
        </Flex>
        <Text
          w="max-content"
          color={ethColor}
          fontSize="sm"
          fontWeight="700"
          me="6px"
        >
          1,924
          <Text as="span" display={{ base: 'none', md: 'unset' }}>
            {' '}
            ETH
          </Text>
        </Text>
      </Flex>
      <SidebarResponsive routes={routes} />
      <Menu>
        <MenuButton>
          {/* <Icon
            mt="6px"
            as={MdNotificationsNone}
            color={navbarIcon}
            w="18px"
            h="18px"
            me="10px"
          /> */}
          {/* <BellIcon /> */}
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="20px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          me={{ base: '30px', md: 'unset' }}
          minW={{ base: 'unset', md: '400px', xl: '450px' }}
          maxW={{ base: '360px', md: 'unset' }}
        >
          <Flex w="100%" mb="20px">
            <Text fontSize="md" fontWeight="600" color={textColor}>
              Notifications
            </Text>
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textColorBrand}
              ms="auto"
              cursor="pointer"
            >
              Mark all read
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              px="0"
              borderRadius="8px"
              mb="10px"
            >
              <ItemContent info="Horizon UI Dashboard PRO" />
            </MenuItem>
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              px="0"
              borderRadius="8px"
              mb="10px"
            >
              <ItemContent info="Horizon Design System Free" />
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>

      <Menu>
        <MenuButton pr="8">
          <Avatar
            src={userProfileImage ? userProfileImage : userProfile}
            height={'32px'}
            width={'32px'}
            marginRight={'4px'}
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Hey,{' '}
              {userFullNName
                ? `${userFullNName ? userFullNName : userName}`
                : `${userFullName ? userFullName : userName}`}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              borderRadius="8px"
              px="14px"
            >
              <Link
                to={'/admin/setting'}
                onClick={() => {
                  analytics.track(`clicked:profile-settings`, {
                    properties: {
                      userRole: currentUser?.role,
                      email: currentUser?.email,
                    },
                  });
                }}
              >
                <Text fontSize="sm">Profile Settings</Text>
              </Link>
            </MenuItem>
            {/* <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Newsletter Settings</Text>
            </MenuItem> */}
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color="red.400"
              borderRadius="8px"
              px="14px"
            >
              <Text onClick={handleLogout} fontSize="sm">
                Log out
              </Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
