// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Style for line around 'or' text */
.divider {
  display: flex;
  align-items: center;
  text-align: center;
  color: #888;
  margin: 20px 0;
}

.divider::before,
.divider::after {
  content: '';
  height: 1px;
  flex: 1 1;
  border-bottom: 1px solid #ddd;
}

.divider:not(:empty)::before {
  margin-right: 0.5em;
}

.divider:not(:empty)::after {
  margin-left: 0.5em;
}
`, "",{"version":3,"sources":["webpack://./src/views/admin/dataTables/SamlModal/samlModal.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,cAAc;AAChB;;AAEA;;EAEE,WAAW;EACX,WAAW;EACX,SAAO;EACP,6BAA6B;AAC/B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["/* Style for line around 'or' text */\n.divider {\n  display: flex;\n  align-items: center;\n  text-align: center;\n  color: #888;\n  margin: 20px 0;\n}\n\n.divider::before,\n.divider::after {\n  content: '';\n  height: 1px;\n  flex: 1;\n  border-bottom: 1px solid #ddd;\n}\n\n.divider:not(:empty)::before {\n  margin-right: 0.5em;\n}\n\n.divider:not(:empty)::after {\n  margin-left: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
