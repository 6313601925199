import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from '@chakra-ui/react';
import TwellaLogoIcon from 'assets/icons/twellaLogo';
import backgroundSpreadImg from 'assets/img/backgroundSpreadImg.png';
import ExclamationTriangleAlertIcon from 'assets/icons/exclamationTriangleAlertIcon';
// import { createClerkClient } from '@clerk/clerk-sdk-node';
import { useClerk, useSignIn } from '@clerk/clerk-react';
import axios from 'axios';
import { IoEye } from 'react-icons/io5';
import { IoMdEyeOff } from 'react-icons/io';
import api from '../../../utils/api';

function SignIn() {
  const [currentSession] = useState(sessionStorage.getItem('session'));
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [submitEmail, setSubmitEmail] = useState(false);
  const [otp, setOTP] = useState(Array(6).fill(''));
  const [currentIndex, setCurrentIndex] = useState(0);
  const [otpError, setOtpError] = useState(false);
  const inputRefs = useRef([]);
  const [codeExpired, setCodeExpired] = useState(false);
  const [timer, setTimer] = useState(900); // eslint-disable-line
  const timerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSignInUsingPassword, setIsSignInUsingPassword] = useState();
  const [isFormValid, setIsFormValid] = useState(false);
  const [password, setPassword] = useState('');
  const [show, setShow] = useState(false);
  const toast = useToast();
  const { setActive, signOut } = useClerk();
  const navigate = useNavigate();
  const textColorSecondary = 'gray.400';
  const handleClick = () => setShow(!show);

  const { signIn, isLoaded: isSignInLoaded } = useSignIn();

  useEffect(() => {
    if (!currentSession) {
      signOut();
      sessionStorage.setItem('session', 'session');
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    // signOut();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(email);
    setIsEmailValid(isEmailValid);
    const isPasswordValid = password.length >= 8;
    setIsFormValid(isEmailValid && isPasswordValid);
  }, [email, password]);

  useEffect(() => {
    if (submitEmail && !codeExpired) {
      timerRef.current = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(timerRef.current);
            setCodeExpired(true);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [submitEmail, codeExpired]);

  const handleSendOTP = async () => {
    if (!isSignInLoaded) {
      console.error('Sign-in object is not loaded yet');
      return;
    }

    setIsLoading(true);
    try {
      // Start the sign-in process
      const signInAttempt = await signIn.create({
        identifier: email,
      });

      // Prepare and send the email code
      await signInAttempt.prepareFirstFactor({
        strategy: 'email_code',
        emailAddressId: signInAttempt.supportedFirstFactors.find(
          (factor) => factor.strategy === 'email_code',
        ).emailAddressId,
      });

      setSubmitEmail(true);
      toast({
        title: 'OTP sent',
        description: 'Please check your email for the OTP',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error sending OTP:', error);
      let errorMessage = 'An error occurred while sending the OTP';
      if (error.errors && error.errors.length > 0) {
        errorMessage = error.errors[0].message;
      }
      toast({
        title: 'Failed to send OTP',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOTPChange = (e, index) => {
    const value = e.target.value.slice(-1).toUpperCase();
    if (!/^[A-Z0-9]$/.test(value) && value !== '') return;

    const newOTP = [...otp];
    newOTP[index] = value;

    setOTP(newOTP);
    setOtpError(false);

    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
      setCurrentIndex(index + 1);
    } else if (index === 5 && value) {
      setTimeout(() => {
        if (newOTP.every((digit) => digit.length === 1)) {
          handleOTPSubmit(newOTP);
        }
      }, 0);
    }
  };

  function checkDevEmail(userEmail) {
    const clerkKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
    const isProdEnv = clerkKey?.includes('live') ? true : false;
    const devEmail = 'dev@twella.app';
    if (isProdEnv && userEmail === devEmail) {
      return true;
    }
    return false;
  }

  const handleOTPSubmit = async (submittedOTP = otp) => {
    if (!isSignInLoaded) {
      console.error('Sign-in object is not loaded yet');
      return;
    }

    const otpCode = submittedOTP.join('');

    setIsLoading(true);

    try {
      let authObj = {};
      if (checkDevEmail(email)) {
        authObj = {
          strategy: 'password',
          password: 'Secure123',
        };
      } else {
        authObj = {
          strategy: 'email_code',
          code: otpCode,
        };
      }
      const signInAttempt = await signIn.attemptFirstFactor(authObj);

      if (signInAttempt.status === 'complete') {
        // Set the active session
        await setActive({ session: signInAttempt.createdSessionId });

        const response = await api.post(`/dashboard/auth/signinWithClerk`, {
          email: email,
        });

        const token = response.data.data.token;
        const user = response.data.data.user;

        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem(
          'institution',
          JSON.stringify(user?.institution[0]),
        );
        // localStorage.setItem('token', signInAttempt.createdSessionId);
        toast({
          title: 'Authentication successful',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        // Use navigate after setting the active session
        navigate('/admin/peoples', { replace: true });
      } else {
        setOtpError(true);
        toast({
          title: 'Invalid OTP',
          description: 'Please try again',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      let errorMessage = 'An error occurred during verification';
      if (error.errors && error.errors.length > 0) {
        errorMessage = error.errors[0].message;
      }
      setOtpError(true);
      toast({
        title: 'Verification failed',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendCode = () => {
    setCodeExpired(false);
    setTimer(900);
    setOTP(Array(6).fill(''));
    setCurrentIndex(0);
    setOtpError(false);
    handleSendOTP();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid) {
      setIsLoading(true);
      try {
        const { createdSessionId } = await signIn?.create({
          identifier: email,
          password,
        });

        if (createdSessionId) {
          await setActive({ session: createdSessionId });

          const response = await api.post(`/dashboard/auth/signinWithClerk`, {
            email: email,
          });
          const token = response?.data?.data?.token;
          const user = response?.data?.data?.user;

          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem(
            'institution',
            JSON.stringify(user?.institution[0]),
          );
          sessionStorage.setItem('test', 'just testing');
          toast({
            title: 'Sign in successful',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          navigate('/admin/peoples', { replace: true });
        }
      } catch (error) {
        let errorMessage = 'An error occurred during sign in';
        if (error?.errors && error.errors?.length > 0) {
          errorMessage = error.errors[0].message;
        }

        if (error?.response && error.response?.data) {
          errorMessage =
            error.response?.data?.message || 'An error occurred during sign in';
        }
        console.error('Sign in error:', error);
        toast({
          title: 'Sign in failed',
          description: errorMessage,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Handle pasting OTP directly
  const handlePaste = (e) => {
    const length = 6;
    const pastedData = e.clipboardData.getData('text').slice(0, length);
    const newOtp = pastedData.split('');

    // Fill input fields and auto-complete if valid
    if (newOtp.length === length) {
      setOTP(newOtp);
      //onComplete(newOtp.join(""));
    } else {
      setOTP([...newOtp, ...new Array(length - newOtp.length).fill('')]);
    }

    // Focus the last filled input field
    inputRefs.current[newOtp.length - 1]?.focus();
    handleOTPSubmit(newOtp);
  };

  // useEffect(() => {
  //   const handleSessionTimeout = (error) => {
  //     // Check if the error indicates a session timeout
  //     if (
  //       error.response &&
  //       error.response.status === 401 &&
  //       error.response.data.message.includes('session')
  //     ) {
  //       // Clear any stored session/authentication data
  //       localStorage.removeItem('token');
  //       localStorage.removeItem('user');

  //       // Sign out the user
  //       signOut();

  //       // Redirect the user to the login/sign-in page
  //       navigate('/signin');
  //     }
  //   };

  //   // Set up a global error handler to catch all API errors
  //   const errorHandler = (error) => {
  //     handleSessionTimeout(error);
  //     // Handle other errors as needed
  //   };

  //   // Attach the error handler to the global error event or a custom axios interceptor
  //   window.addEventListener('error', errorHandler);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('error', errorHandler);
  //   };
  // }, [navigate]);

  return (
    <Flex
      h="100vh"
      alignItems="center"
      px={{ base: '25px', md: '0px' }}
      flexDirection="column"
      bg={'#FFF8F3'}
      width={'100%'}
    >
      <Flex
        flexDirection={'column'}
        width={'80%'}
        alignItems={'center'}
        backgroundImage={backgroundSpreadImg}
        backgroundRepeat={'no-repeat'}
        backgroundSize={'cover'}
        backgroundPosition={'center'}
        height={'100vh'}
        pt={'68px'}
      >
        <Box mb={'58px'}>
          <TwellaLogoIcon width={'106px'} height={'38px'} />
        </Box>
        <Box>
          <Heading
            color={'#222222'}
            fontWeight={'700'}
            fontSize="40px"
            mb="24px"
            fontFamily={'DM Sans'}
          >
            {submitEmail ? 'Check your email for code' : 'Sign in to Dashboard'}
          </Heading>
        </Box>

        {isSignInUsingPassword ? (
          <Flex
            zIndex="2"
            direction="column"
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: 'auto', lg: 'unset' }}
            me="auto"
            bg={'#FFFDFBCC'}
            p={'32px'}
            border={'1px solid #E7E7E7'}
            width={'564px'}
          >
            <FormControl as="form" onSubmit={handleSubmit}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="12px"
                fontWeight="700"
                color={'#222222'}
                mb="8px"
              >
                <Text>Email</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                type="email"
                placeholder="name@email.com"
                mb="24px"
                fontWeight="500"
                height="44px"
                borderRadius={'8px'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="12px"
                fontWeight="700"
                color={'#222222'}
                mb="8px"
              >
                <Text>Password</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="use at least 8 digits"
                  mb="24px"
                  size="lg"
                  type={show ? 'text' : 'password'}
                  variant="auth"
                  height="44px"
                  borderRadius={'8px'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: 'pointer' }}
                    as={show ? IoEye : IoMdEyeOff}
                    onClick={handleClick}
                    height={'20px'}
                    width={'20px'}
                  />
                </InputRightElement>
              </InputGroup>
              <Button
                fontSize="14px"
                fontWeight="700"
                w="100%"
                h="40px"
                color={'white'}
                bg={isFormValid ? '#EF6506' : '#EF650680'}
                _hover={isFormValid ? { bg: '#FF7A1F' } : {}}
                borderRadius={'8px'}
                type="submit"
                isDisabled={!isFormValid || isLoading}
                isLoading={isLoading}
              >
                Sign In
              </Button>
            </FormControl>
          </Flex>
        ) : (
          <Flex
            zIndex="2"
            direction="column"
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: 'auto', lg: 'unset' }}
            me="auto"
            bg={'#FFFDFBCC'}
            p={'32px'}
            border={'1px solid #E7E7E7'}
            width={'564px'}
          >
            <FormControl as="form" onSubmit={(e) => e.preventDefault()}>
              {submitEmail ? (
                <>
                  <Text
                    fontSize="14px"
                    fontWeight="500"
                    mb="12px"
                    textAlign="center"
                  >
                    We have sent a 6-digit code to{' '}
                    <span style={{ fontWeight: '700' }}>{email}</span>. The code
                    expires shortly, so enter it soon.
                  </Text>

                  <Flex
                    mb="24px"
                    justify="center"
                    align="center"
                    onPaste={handlePaste}
                  >
                    {[...Array(6)].map((_, index) => (
                      <React.Fragment key={index}>
                        <Input
                          ref={(el) => (inputRefs.current[index] = el)}
                          isRequired={true}
                          type="text"
                          maxLength="1"
                          fontSize="48px"
                          fontWeight="700"
                          textAlign="center"
                          variant="unstyled"
                          height="100px"
                          width="72px"
                          borderRadius="8px"
                          border="1px solid"
                          borderColor={
                            index === currentIndex ? '#EF6506' : '#E2E8F0'
                          }
                          value={otp[index] || ''}
                          onChange={(e) => handleOTPChange(e, index)}
                          onKeyDown={(e) => {
                            if (e.key === 'Backspace' && index > 0) {
                              const newOTP = [...otp];
                              newOTP[index] = '';
                              setOTP(newOTP);
                              inputRefs.current[index - 1].focus();
                              setCurrentIndex(index - 1);
                            }
                          }}
                          mx="4px"
                          _hover={'#EF6506'}
                        />
                        {index === 2 && (
                          <Text
                            fontSize="48px"
                            fontWeight="400"
                            mx="4px"
                            color={'#222222'}
                          >
                            -
                          </Text>
                        )}
                      </React.Fragment>
                    ))}
                  </Flex>
                  <Flex mb="24px" justify="center" align="center">
                    <Button
                      fontSize="14px"
                      fontWeight="700"
                      w="100%"
                      h="40px"
                      color={'white'}
                      bg={isFormValid ? '#EF6506' : '#EF650680'}
                      _hover={isFormValid ? { bg: '#FF7A1F' } : {}}
                      borderRadius={'8px'}
                      type="button"
                      isLoading={isLoading}
                    >
                      Sign In
                    </Button>
                  </Flex>
                </>
              ) : (
                <>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="12px"
                    fontWeight="700"
                    color={'#222222'}
                    mb="8px"
                  >
                    <Text>Email</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    type="email"
                    placeholder="name@email.com"
                    mb="24px"
                    fontWeight="500"
                    height="44px"
                    borderRadius={'8px'}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Button
                    fontSize="14px"
                    fontWeight="700"
                    w="100%"
                    h="40px"
                    color={'white'}
                    bg={'#EF6506'}
                    _hover={isEmailValid ? { bg: '#FF7A1F' } : {}}
                    borderRadius={'8px'}
                    type="submit"
                    isDisabled={!isEmailValid || isLoading}
                    onClick={handleSendOTP}
                    isLoading={isLoading}
                  >
                    Sign In
                  </Button>
                </>
              )}
              {/* Below input box */}
              {(!submitEmail || otpError || codeExpired) && (
                <Flex
                  height={'80px'}
                  bg={otpError || codeExpired ? '#FFE3DD' : '#FFEEE3'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  padding={'16px'}
                  borderRadius={'8px'}
                  mt={'24px'}
                >
                  <Box
                    fontSize={'16px'}
                    fontWeight={'400'}
                    letterSpacing={'0.1px'}
                  >
                    {otpError ? (
                      <span
                        style={{
                          color: '#E02B00',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <ExclamationTriangleAlertIcon />
                        This code wasn't valid. Have another try!
                      </span>
                    ) : submitEmail && codeExpired ? (
                      <span
                        style={{
                          color: '#E02B00',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <ExclamationTriangleAlertIcon />
                        Your code has expired.
                        <Text
                          as="span"
                          color={'#222222'}
                          fontWeight={'600'}
                          cursor={'pointer'}
                          onClick={handleResendCode}
                          textDecoration={'underline'}
                          textDecorationThickness={'1px'}
                          // borderBottom={'1px solid black'}
                        >
                          Resend Code
                        </Text>
                      </span>
                    ) : (
                      <>
                        <span>
                          We will send a code to your email for a password-free
                          sign in. Or you can
                        </span>
                        <span
                          style={{
                            color: '#EF6506',
                            marginLeft: '4px',
                            fontWeight: '600',
                            cursor: 'pointer',
                          }}
                          onClick={() => setIsSignInUsingPassword(true)}
                        >
                          sign in with a password instead
                        </span>
                        .
                      </>
                    )}
                  </Box>
                </Flex>
              )}
            </FormControl>
          </Flex>
        )}
        <Flex
          flexDirection={'column'}
          pt={'15px'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={'8px'}
          fontSize={'14px'}
          lineHeight={'20px'}
          fontWeight={'500'}
          color={'#222222'}
        >
          <Flex>
            {submitEmail ? (
              <>
                <Text>Can not find a code? </Text>
                <Text ml={'4px'}>Check your spam folder! </Text>
              </>
            ) : (
              <>
                <Text>Need help with Sign in? </Text>
                <Text
                  fontSize={'14px'}
                  lineHeight={'20px'}
                  fontWeight={'600'}
                  color={'#EF6506'}
                  letterSpacing={'0.1px'}
                  ml={'4px'}
                >
                  Contact support
                </Text>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignIn;
