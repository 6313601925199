import { Box, Flex, Spinner, Text, useToast } from '@chakra-ui/react';
import Twitter from 'assets/icons/Twitter';
import Instagram from 'assets/icons/Instagram';
import TikTok from 'assets/icons/TikTok';
import YouTube from 'assets/icons/YouTube';
import Facebook from 'assets/icons/Facebook';
import Linkedin from 'assets/icons/LinkedIn';
import Reddit from 'assets/icons/Reddit';
import React, { useEffect, useState } from 'react';
import analytics from '../../../../segmentTracking';
import { Switch } from '../../../../components/Switch/index';
import { handleSessionTimeout } from 'utils/sessionManagementUtils';
import api from '../../../../utils/api';

let socialMediaList = [
  {
    name: 'X (formerly Twitter)',
    type: 1,
    icon: Twitter,
    nameCompare: 'Twitter',
  },
  {
    name: 'Instagram',
    type: 1,
    icon: Instagram,
    nameCompare: 'Instagram',
  },
  {
    name: 'Tik Tok',
    type: 1,
    icon: TikTok,
    nameCompare: 'TikTok',
  },
  {
    name: 'YouTube shorts',
    type: 1,
    icon: YouTube,
    nameCompare: 'YouTube',
  },
  {
    name: 'Facebook',
    type: 0,
    icon: Facebook,
    nameCompare: 'Facebook',
  },
  {
    name: 'LinkedIn',
    type: 0,
    icon: Linkedin,
    nameCompare: 'Linkedin',
  },
  {
    name: 'Reddit',
    type: 0,
    icon: Reddit,
    nameCompare: 'Reddit',
  },
];

const SocialMediaManagement = () => {
  const toast = useToast();
  const [switchStates, setSwitchStates] = useState({});
  const [socialMedia, setSocialMedia] = useState([]);
  const [loadingStates, setLoadingStates] = useState(false);
  const [currentUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [contentChanged, setContentChanged] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const token = localStorage.getItem('token');
  const currentInstitution = JSON.parse(localStorage.getItem('institution'));

  const handleSwitchChange = async (id) => {
    setLoadingStates((prev) => ({ ...prev, [id]: true }));
    const token = localStorage.getItem('token');
    const currentInstitution = JSON.parse(localStorage.getItem('institution'));
    if (!token || !currentInstitution) {
      console.error('Token or institution not found');
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    // Determine the current switch state
    const isSwitchOn = switchStates[id];

    try {
      const url = `/dashboard/institute/${
        isSwitchOn ? 'removePlatform' : 'addPlatform'
      }/${currentInstitution?._id}`;

      const body = { platform: id };
      let response;

      if (isSwitchOn) {
        response = await api.patch(url, body, { headers });
      } else {
        response = await api.post(url, body, { headers });
      }

      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }

      // Update the switch state
      setSwitchStates((prevStates) => ({
        ...prevStates,
        [id]: !isSwitchOn,
      }));

      const platformName =
        socialMediaList.find(
          (media) =>
            media.nameCompare ===
            socialMedia.find((item) => item._id === id)?.name,
        )?.name || 'Unknown Platform';

      analytics.track(`settings:data:socialmedia:toggled`, {
        platform: platformName,
        status: isSwitchOn ? 'disabled' : 'enabled',
        email: currentUser?.email,
        userRole: currentUser?.role,
      });

      toast({
        title: `Successful`,
        description: !isSwitchOn ? 'Platform Removed' : 'Platform Added',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setLoadingStates((prev) => ({ ...prev, [id]: false }));
      //   console.log(`Switch ${id} is ${isSwitchOn ? 'OFF' : 'ON'}`);
    } catch (error) {
      console.error(
        'Error:',
        error.response ? error.response.data : error.message,
      );
      setSwitchStates((prevStates) => ({
        ...prevStates,
        [id]: !prevStates[id],
      }));
    } finally {
      setLoadingStates((prev) => ({ ...prev, [id]: false }));
    }
  };

  const fetchData = async () => {
    if (!token || !currentInstitution) {
      console.error('Token or institution not found');
      return;
    }

    try {
      const url = `/dashboard/institute/getInstitutePlatforms?institution=${currentInstitution?._id}`;
      const response = await api.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setSocialMedia(response.data.data);
    } catch (error) {
      handleSessionTimeout(error);
      console.log(error.message);
    }
  };

  const handleContentChange = async (value) => {
    console.log('18+', value);

    try {
      setContentLoading(true);
      const url = `/dashboard/institute/updateInstituteContent?id=${currentInstitution?._id}`;
      await api.post(url, { adultContentAllowed: value });

      toast({
        title: `Successful`,
        description: value ? 'Adult Content Enabled' : 'Adult Content Disabled',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setContentLoading(false);
      getCurrentInstitute();
    } catch (error) {
      setContentLoading(false);
      console.log(error.message);
    }
  };

  const getCurrentInstitute = async () => {
    try {
      const url = `/dashboard/institute/getCurrentInstitute?id=${currentInstitution?._id}`;
      const response = await api.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const data = response.data.data;
      setContentChanged(data?.adultContentAllowed);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [switchStates]);

  useEffect(() => {
    getCurrentInstitute();
  }, []);
  return (
    <Box width={'65%'} margin={'24px auto 48px'}>
      <Box>
        <Text
          textTransform={'capitalize'}
          fontSize={'20px'}
          fontWeight={'700'}
          lineHeight={'24px'}
        >
          Social media
        </Text>
        <Flex flexDir={'column'} margin={'10px 0'}>
          <Flex justifyContent={'space-between'} alignItems={'flex-start'}>
            <Box flexBasis={'55%'}>
              <Text
                fontSize={'14px'}
                lineHeight={'20px'}
                fontWeight={'400'}
                color={'#757575'}
                pt={'4px'}
              >
                Manage networks that your students should view content from
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Box>

      <Box mt={'20px'}>
        {socialMediaList.length === 0 ? (
          <Text>No social media platforms available.</Text>
        ) : (
          <Flex flexDir={'column'} margin={'10px 0'}>
            {socialMediaList.map((media) => {
              const foundMedia = socialMedia.find(
                (item) => item.name === media.nameCompare,
              );

              // Check if foundMedia exists and get its disabled state
              const disabledState = foundMedia ? foundMedia.disabled : false;
              const _id = foundMedia ? foundMedia._id : null;
              const isLoading = loadingStates[_id] || false;
              //   console.log(
              //     `disabledState - ${media.nameCompare} - ${_id}`,
              //     disabledState,
              //   );
              return (
                <Flex
                  key={media.id}
                  flexDir={'column'}
                  paddingBottom={'12px'}
                  paddingTop={'12px'}
                  borderBottom={
                    media.name === 'Reddit' ? '0px' : '1px solid #E7E7E7'
                  }
                  opacity={media.type === 1 ? '1' : '0.3'}
                >
                  <Flex
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                  >
                    <Flex
                      justifyContent={'space-between'}
                      alignItems={'flex-start'}
                    >
                      {media.icon && (
                        <Box as={media.icon} fill={'#FFFDFB'} mr="8px" />
                      )}
                      {
                        <Text
                          style={{ marginRight: media.icon ? '0px' : '25px' }}
                        ></Text>
                      }
                      {/* Render the icon */}
                      <Text
                        fontSize={'14px'}
                        lineHeight={'20px'}
                        fontWeight={'700'}
                        color={'#222222'}
                        pt={'4px'}
                        marginLeft="10px"
                      >
                        {media.name}
                      </Text>
                    </Flex>
                    <Box justifyContent="space-between" alignItems="center">
                      {isLoading ? (
                        <Flex
                          height={'22px'}
                          width={'44px'}
                          justifyContent={'center'}
                          alignItems={'center'}
                        >
                          <Spinner size={'sm'} color="#EF6506" />
                        </Flex>
                      ) : (
                        <Switch
                          id={_id}
                          checked={
                            (!disabledState && media.type === 1) || false
                          }
                          onCheckedChange={() => handleSwitchChange(_id)}
                          disabled={media.type === 1 ? false : true}
                          className=""
                          // sx={{
                          //   '& .chakra-switch__track': {
                          //     backgroundColor: '#C2C2C2',
                          //   },
                          //   '& .chakra-switch__track[data-checked]': {
                          //     backgroundColor: '#EF6506',
                          //   },
                          // }}
                        />
                      )}
                    </Box>
                  </Flex>
                </Flex>
              );
            })}

            <Flex
              key={'18+'}
              flexDir={'column'}
              paddingBottom={'12px'}
              paddingTop={'12px'}
              borderBottom={'0px'}
              opacity={'1'}
            >
              <Flex justifyContent={'space-between'} alignItems={'flex-start'}>
                <Flex
                  justifyContent={'space-between'}
                  alignItems={'flex-start'}
                >
                  <Text style={{ marginRight: '25px' }}></Text>

                  {/* Render the icon */}
                  <Text
                    fontSize={'14px'}
                    lineHeight={'20px'}
                    fontWeight={'700'}
                    color={'#222222'}
                    pt={'4px'}
                    marginLeft="10px"
                  >
                    {'18+'}
                  </Text>
                </Flex>
                <Box justifyContent="space-between" alignItems="center">
                  {contentLoading ? (
                    <Flex
                      height={'22px'}
                      width={'44px'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Spinner size={'sm'} color="#EF6506" />
                    </Flex>
                  ) : (
                    <Switch
                      id={'_id'}
                      checked={contentChanged}
                      onCheckedChange={(event) => {
                        handleContentChange(event);
                      }}
                      sx={{
                        '& .chakra-switch__track': {
                          backgroundColor: '#C2C2C2',
                        },
                        '& .chakra-switch__track[data-checked]': {
                          backgroundColor: '#EF6506',
                        },
                      }}
                    />
                  )}
                </Box>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default SocialMediaManagement;
