import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
  HStack,
  Tooltip,
  Icon,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import analytics from '../../../../segmentTracking';
import api from '../../../../utils/api';
import './samlModal.css';

const SamlModal = ({ onClose, isOpen, onAddSaml, samlData }) => {
  const [data, setData] = useState({
    domain: '',
    ssoUrl: '',
    metadata: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [currentInstitution] = useState(
    JSON.parse(localStorage.getItem('institution')),
  );
  const toast = useToast();

  const handleSubmit = async (e) => {
    e?.preventDefault();

    if (Object.values(data).filter(Boolean).length < 3) {
      toast({
        title: 'Error',
        description: 'Missing fields.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsLoading(true);

      let message = '';
      if (samlData) {
        message = 'Saml Connection updated successfully.';
        await api.post(
          `/dashboard/institute/updateSaml?id=${samlData?._id}`,
          data,
        );
      } else {
        message = 'Saml Connection added successfully.';
        await api.post(
          `/dashboard/institute/addSaml?instititueId=${currentInstitution?._id}`,
          data,
        );
      }
      setData({ domain: '', entityId: '', ssoUrl: '' });
      onClose();
      onAddSaml(message);
    } catch (error) {
      console.log('saml error', error);
      toast({
        title: 'Error',
        description:
          error.response?.data?.message ||
          'An error occurred while adding saml',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (samlData) {
      setData({
        domain: samlData.domain,
        ssoUrl: samlData.ssoUrl,
        metadata: samlData.metadata,
      });
    }
  }, [samlData]);

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
      <ModalOverlay bg="#53535399" />
      <ModalContent
        bg={bgColor}
        borderColor={borderColor}
        borderWidth="1px"
        borderRadius={'12px'}
        alignSelf={'center'}
      >
        <ModalHeader paddingBottom={0}>SAML</ModalHeader>
        <ModalCloseButton style={{ marginTop: '8px' }} />
        <ModalBody paddingTop={0}>
          <Text fontSize="sm" color="gray.500" mb={'16px'}>
            Single Sign On via Saml
          </Text>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel htmlFor="domain" fontSize="14px" fontWeight="700">
                  Domain &nbsp;
                  <Tooltip label="Specify the email domain, e.g.  gmail.com, hotmail.com.">
                    <InfoOutlineIcon cursor="pointer" />
                  </Tooltip>
                </FormLabel>

                <Input
                  id="domain"
                  type="text"
                  value={data.domain}
                  onChange={(e) => setData({ ...data, domain: e.target.value })}
                  placeholder="Enter Domain"
                  borderColor="#AAAAAA"
                  focusBorderColor="#EF6506"
                  _hover={{ border: '1px solid #EF6506' }}
                  borderRadius="8px"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="ssourl" fontSize="14px" fontWeight="700">
                  SSO URL&nbsp;&nbsp;
                  <Tooltip label="To find your Identity Provider's SSO URL, navigate to the SAML settings in your IdP's admin dashboard and look for the SSO Endpoint for the SSO URL.">
                    <InfoOutlineIcon cursor="pointer" />
                  </Tooltip>
                </FormLabel>

                <Input
                  id="ssourl"
                  type="text"
                  value={data.ssoUrl}
                  onChange={(e) => setData({ ...data, ssoUrl: e.target.value })}
                  placeholder="Enter SSO URL"
                  borderColor="#AAAAAA"
                  focusBorderColor="#EF6506"
                  _hover={{ border: '1px solid #EF6506' }}
                  borderRadius="8px"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="metadata" fontSize="14px" fontWeight="700">
                  Metadata URL&nbsp;&nbsp;
                  <Tooltip label="To find your Identity Provider's Metadata URL, navigate to the SAML settings in your IdP's admin dashboard and look for the Metadata URL.">
                    <InfoOutlineIcon cursor="pointer" />
                  </Tooltip>
                </FormLabel>
                <Input
                  id="metadata"
                  type="text"
                  value={data.metadata}
                  onChange={(e) =>
                    setData({ ...data, metadata: e.target.value })
                  }
                  placeholder="Enter Metadata URL"
                  borderColor="#AAAAAA"
                  focusBorderColor="#EF6506"
                  _hover={{ border: '1px solid #EF6506' }}
                  borderRadius="8px"
                />
              </FormControl>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={3}>
            <Button
              variant="outline"
              onClick={onClose}
              borderRadius="8px"
              padding="6px 12px"
            >
              Cancel
            </Button>
            <Button
              bg="#EF6506"
              color="white"
              onClick={handleSubmit}
              borderRadius="8px"
              borderColor="#AAAAAA"
              height="36px"
              fontSize="14px"
              fontWeight="700"
              _hover={{ bg: '#d15b05' }}
              isLoading={isLoading}
              padding="6px 12px"
            >
              {samlData ? 'Update SAML' : 'Add SAML'}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SamlModal;
