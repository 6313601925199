// Chakra Imports
import { Box, Flex, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import Brand from 'components/sidebar/components/Brand';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../Select';

export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));
  const [currentInstitution, setCurrentInstitution] = useState(
    JSON.parse(localStorage.getItem('institution')),
  );

  useEffect(() => {
    window.addEventListener('scroll', changeNavbar);

    return () => {
      window.removeEventListener('scroll', changeNavbar);
    };
  });

  const { secondary, message } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  // let mainText = useColorModeValue('navy.700', 'white');
  // let secondaryText = useColorModeValue('gray.700', 'white');
  let navbarPosition = 'fixed';
  let navbarFilter = 'none';
  let navbarBackdrop = 'blur(20px)';
  let navbarShadow = 'none';
  // let navbarBg = useColorModeValue(
  //   'rgba(244, 247, 254, 0.2)',
  //   'rgba(11,20,55,0.5)',
  // );
  let navbarBorder = 'transparent';
  let secondaryMargin = '0px';
  // let paddingX = '15px';
  let gap = '0px';
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  function handleInstitutionChange(id) {
    const selectedInstitution = user?.institution?.find((value) => {
      return value?._id === id;
    });
    localStorage.setItem('institution', JSON.stringify(selectedInstitution));
    setCurrentInstitution(selectedInstitution);
    window.location.reload();
  }

  const getCurrentInstitutionName = (institutionArray, value) => {
    const institution = institutionArray?.filter((elem) => elem._id === value);
    console.log(institution[0].name);
    return institution[0].name;
  };

  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={'#FFF8F3'}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      //   borderRadius="16px"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: 'center' }}
      display={secondary ? 'block' : 'flex'}
      minH="48px"
      justifyContent={{ xl: 'center' }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      //   right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
      //   px={{
      //     sm: paddingX,
      //     md: '10px',
      //   }}
      ps={{
        xl: '12px',
      }}
      pt="8px"
      //   top={{ base: '12px', md: '16px', lg: '20px', xl: '20px' }}
      w={
        // {
        //   base: 'calc(100vw - 5%)',
        //   md: 'calc(100vw - 8%)',
        //   lg: 'calc(100vw - 6%)',
        //   xl: 'calc(100vw - 165px)',
        //   '2xl': 'calc(100vw - 365px)',

        // }
        '100%'
      }
      //   backgroundColor={'red'}
    >
      <Flex
        w="100%"
        flexDirection={{
          sm: 'column',
          md: 'row',
        }}
        alignItems={{ xl: 'center' }}
        mb={gap}
      >
        <Box mb={{ sm: '8px', md: '0px' }}>
          {/* Here we create navbar brand, based on route name */}
          <Brand />
          {/* <Link
            color={mainText}
            href="#"
            bg="inherit"
            borderRadius="inherit"
            fontWeight="bold"
            fontSize="28px"
            _hover={{ color: { mainText } }}
            _active={{
              bg: 'inherit',
              transform: 'none',
              borderColor: 'transparent',
            }}
            _focus={{
              boxShadow: 'none',
            }}
          >
            {brandText}
          </Link> */}
        </Box>
        {user?.role === 'super_admin' ? (
          <Box ml={{ sm: '15px', md: '15px' }} mb={{ sm: '8px', md: '0px' }}>
            <Select onValueChange={(event) => handleInstitutionChange(event)}>
              <SelectTrigger className="w-auto">
                <SelectValue
                  placeholder={getCurrentInstitutionName(
                    user?.institution,
                    currentInstitution?._id,
                  )}
                />
              </SelectTrigger>
              <SelectContent>
                {user?.institution?.map((elem, index) => (
                  <SelectItem value={elem?._id} key={index}>
                    {elem?.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </Box>
        ) : (
          <Box
            borderRadius="8px"
            padding="7px 15px"
            border="1px solid #E7E7E7"
            cursor="context-menu"
            _hover={{ borderColor: '#EF6506' }}
            ml={{ sm: '15px', md: '15px' }}
            mb={{ sm: '8px', md: '0px' }}
          >
            <Text>{currentInstitution?.name}</Text>
          </Box>
        )}
        <Box ms="auto" w={{ sm: '100%', md: 'unset' }}>
          <AdminNavbarLinks
            onOpen={props.onOpen}
            logoText={props.logoText}
            secondary={props.secondary}
            fixed={props.fixed}
            scrolled={scrolled}
          />
        </Box>
      </Flex>
      {secondary ? <Text color="white">{message}</Text> : null}
    </Box>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
